<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Popups</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Popups
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    @click="createPopup()"
                    class="mt-1 btn btn-block btn-primary"
                    style="color:#fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add new
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="2">
                    <v-text-field
                        label="Name"
                        v-model="search.name"
                        v-on:keyup.enter="searchPopups"
                        @input="search.name = $event !== null ? $event : ''"
                        clearable
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>
                <v-col cols="12" sm="6" md="2">
                    <v-select
                        label="Type"
                        v-model="search.type"
                        v-on:keyup.enter="searchPopups"
                        @input="search.type = $event !== null ? $event : ''"
                        :items="types"
                        item-text="name"
                        item-value="value"
                        clearable
                        outlined
                        dense
                    ></v-select>
                  </v-col>
                <v-col class="d-flex" cols="12" sm="12" md="2">
                  <v-menu
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.start_date"
                          label="View from date"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          v-on:keyup.enter="searchPopups()"
                          @input="search.start_date = $event !== null ? $event : ''"
                          :error="checkDateFrom"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        no-title
                        @input="menuStartDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12" md="2">
                  <v-menu
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.end_date"
                          label="View up to date"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          v-on:keyup.enter="searchPopups()"
                          :error="checkDateTo"
                          @input="search.end_date = $event !== null ? $event : ''"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.end_date"
                        no-title
                        @input="menuEndDate = false"
                    ></v-date-picker>
                  </v-menu><br>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Status"
                      v-model="search.is_active"
                      v-on:keyup.enter="searchPopups"
                      @input="search.is_active = $event !== null ? $event : ''"
                      :items="status"
                      item-text="name"
                      item-value="value"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn
                      @click.prevent="searchPopups"
                      class="btn btn-block btn-primary"
                      style="color: #fff;"
                      :loading="loading"
                      :disabled="checkDateFrom || checkDateTo"
                  >
                      <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                  </v-btn>
                  </v-col>
              </v-row>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="px-3">
                    <th class="px-3" :class="sortedClass('name')" @click="sortBy('name')">Name</th>
                    <th class="px-3">Type</th>
                    <th class="px-3">View from date</th>
                    <th class="px-3">View up to date</th>
                    <th class="px-3">Pages</th>
                    <th class="px-3">Scores</th>
                    <th class="px-3">Status</th>
                    <th class="pr-3 text-center">Options</th>
                  </tr>
                </thead>
                <tbody>
                  <template>
                  <tr v-for="popup in popups" :key="popup.id" >
                    <td class="px-3">
                      <a @click="editPopup(popup)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ popup.name }}
                      </a>
                    </td>
                    <td>
                        {{ popup.type_text }}
                    </td>
                    <td>
                        {{ popup.formatted_start_date ? popup.formatted_start_date : '--' }}
                    </td>
                    <td>
                        {{ popup.formatted_end_date ? popup.formatted_end_date : '--'}}
                    </td>
                    <td class="px-3">
                      <ul v-if="popup.page_text">
                        <li v-for="page in popup.page_text" :key="page.id">
                          {{ page }}
                        </li>
                      </ul>
                    </td>
                    <td class="px-3">
                      <ul v-if="popup.scores">
                        <li v-for="score in popup.scores" :key="score.id">
                          {{ score.name }}
                        </li>
                      </ul>
                      <span v-else>--</span>
                    </td>
                    <td>
                      <span class="badge"
                            v-bind:class="{ 'badge-success': popup.is_active, 'badge-danger': !popup.is_active }"
                      >{{ popup.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editPopup(popup)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deletePopup(popup.id)">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="popups.length == 0" >
                    <td colspan="8" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                v-if="popups.length > 0"
                class="pull-right mt-7"
                @input="getAllPopups"
                :disabled="loading"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllPopups"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import PopupService from "@/services/cms/popup/PopupService";
import CreateAndUpdate from "@/view/pages/view/cms/popup/CreateAndUpdate";

const popup = new PopupService();

export default {
  components: {CreateAndUpdate},
  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      total: null,
      perPage: null,
      page: null,
      popups: [],
      loading: false,
      sort: {
        key: '',
        isAsc: false
      },
      search: {
        name: '',
        type: '',
        start_date: '',
        end_date: '',
        is_active: ''
      },
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      types: [
        { name: 'Image', value: 'image' },
        { name: 'Text', value: 'text' },
        { name: 'Video', value: 'video' },
      ],
    }
  },
  computed: {
    sortedItems() {
      const list = this.popups.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    },
    checkDateFrom() {
      return (new Date(this.search.end_date) <= new Date(this.search.start_date)) || (this.search.end_date != '' && this.search.start_date == '');
    },
    checkDateTo() {
      return (new Date(this.search.end_date) <= new Date(this.search.start_date)) || (this.search.start_date != '' && this.search.end_date == '');
    }
  },
  methods: {
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    getAllPopups() {
      if(this.search.end_date < this.search.start_date) {
        this.$snotify.error("View up to date must be greater than View from date");
        return;
      }
      this.loading = true;
      popup
      .paginate(this.search, this.page)
      .then((response) => {
        this.popups = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        this.$snotify.error("Oops something went wrong");
      });
    },
    searchPopups() {
      this.getAllPopups();
    },
    createPopup() {
      this.$refs['create-and-update'].createPopup();
    },
    editPopup(popup) {
      this.$refs['create-and-update'].editPopup(popup);
    },
    deletePopup(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
            no: "No",
            yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            popup
            .delete(id)
            .then((response) => {
                this.getAllPopups()
                this.$snotify.success("Popup deleted ");
            })
            .catch((err) => {
                this.$snotify.error("Oops something went wrong");
            });
          }
        }
      });
    },
    resetSearch(){
      this.search = {};
      this.getAllPopups();
    },
  },
  mounted(){
    this.getAllPopups();
  }
}
</script>

<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>
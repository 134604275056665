<template>
  <v-dialog
      v-model="dialog"
      width="800px"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} Popup</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="popup.name"
                  :error="$v.popup.name.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.popup.name.$error">Name is required</span>
              <span class="text-danger" v-if="errors.name" >* {{ errors.name[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  v-model="popup.type"
                  :items="types"
                  item-text="name"
                  item-value="value"
                  :error="$v.popup.type.$error"
                  clearable
                  outlined
                  dense
              >
                <template v-slot:label>
                  Type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.popup.type.$error">Position is required</span>
              <span class="text-danger" v-if="errors.type" >* {{ errors.type[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="popup.start_date"
                      prepend-icon=""
                      prepend-inner-icon="mdi-calendar"
                      @input="popup.start_date = $event !== null ? $event : ''"
                      :error="$v.popup.start_date.$error"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      clearable
                  >
                    <template v-slot:label>
                      View from date <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model="popup.start_date"
                    no-title
                    @input="menuStartDate = false"
                ></v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.popup.start_date.$error">View from date is required</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="popup.end_date"
                      label="View up to date"
                      prepend-icon=""
                      prepend-inner-icon="mdi-calendar"
                      @input="popup.end_date = $event !== null ? $event : ''"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      clearable
                  >
                    <template v-slot:label>
                      View up to date <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model="popup.end_date"
                    :min="popup.start_date"
                    no-title
                    @input="menuEndDate = false"
                ></v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.popup.end_date.$error">View up to date is required</span>
            </v-col>

            <v-col cols="12" v-if="popup.type =='image'">
              <v-file-input
                  label="File"
                  v-model="popup.uploadFile"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  clearable
                  outlined
                  dense
              >
              </v-file-input>
            </v-col>

            <v-col cols="12">
              <v-text-field
                  label="Link Url"
                  v-model="popup.link_url"
                  :error="$v.popup.link_url.$error"
                  outlined
                  dense
              >
              </v-text-field>
              <span class="text-danger" v-if="!$v.popup.link_url.url">Url should be valid</span>
            </v-col>

            <v-col cols="12" v-if="popup.type =='video'">
              <v-text-field
                  label="Video Link"
                  v-model="popup.video_link"
                  :error="$v.popup.video_link.$error"
                  outlined
                  dense
              >
              </v-text-field>
              <span class="text-danger" v-if="!$v.popup.video_link.url">Url should be valid</span>
            </v-col>

            <v-col cols="12">
              <v-select
                  item-text="name"
                  item-value="value"
                  v-model="popup.applicable_pages"
                  :error="$v.popup.applicable_pages.$error"
                  :items="pages"
                  multiple
                  small-chips
                  deletable-chips
                  clearable
                  outlined
                  dense
              >
                <template v-slot:label>
                  Applicable pages <span class="text-danger">*</span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="allSelectGrades"
                  >
                    <v-list-item-action>
                      <v-icon :color="popup.applicable_pages > 0 ? 'indigo darken-4' : ''">
                        {{ iconPage }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.popup.applicable_pages.$error">Applicable pages is required</span>
            </v-col>

            <v-col cols="12" md="4">
              <v-switch
                  v-model="popup.score_for"
                  :label="popup.score_for ? 'All SCORE' : 'Specific SCORE'"
                  @change="changeScore"
              ></v-switch>
            </v-col>

            <v-col cols="12" v-if="currentUser.access_type == 'federal' && !popup.score_for">
              <v-select
                  label="Scores"
                  item-text="name"
                  item-value="id"
                  :items="scores"
                  v-model="popup.applicable_scores"
                  multiple
                  small-chips
                  deletable-chips
                  clearable
                  outlined
                  dense
              >
              </v-select>
            </v-col>

            <v-col cols="12" v-if="popup.type == 'text'">
              Description <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="popup.description"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.popup.description.$error">This information is required</span>
              <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
            </v-col>

            <v-col cols="12" md="4">
              Display name
              <v-switch
                  v-model="popup.display_title"
                  :label="popup.display_title ? 'Yes' : 'No'"
              ></v-switch>
            </v-col>

            <v-col cols="12" md="4">
              Status
              <v-switch
                  v-model="popup.is_active"
                  :label="popup.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            class="cancel-btn"
            x-large
            text
            @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
           x-large
           dark
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, url, requiredIf} from "vuelidate/lib/validators";
import PopupService from "@/services/cms/popup/PopupService";
import PageService from "@/services/cms/page/PageService";
import ScoreService from "@/services/score/score/ScoreService";

const popup = new PopupService();
const score = new ScoreService();
const page = new PageService();

export default {
  validations: {
    popup: {
      name: { required },
      description: { required: requiredIf(function () {
        return this.popup.type == 'text';
        }) },
      video_link: { url, required: requiredIf(function () {
        return this.popup.type == 'video';
        }) },
      type: { required },
      applicable_pages: { required },
      start_date: { required },
      end_date: { required },
      link_url: { url },
    }
  },
  data() {
    return{
      loading: false,
      menuStartDate: false,
      menuEndDate: false,
      dialog: false,
      edit: false,
      title: '',
      scores: [],
      pages: [],
      errors: [],
      currentUser: {},
      currentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      types: [
          { name: "Image", value: "image" },
          { name: "Text", value: "text" },
          { name: "Video", value: "video" }
      ],
      popup: {
        name: '',
        description: '',
        display_title: true,
        type: 'text',
        uploadFile: null,
        link_url: '',
        video_link: '',
        score_for: true,
        applicable_pages: [],
        applicable_scores: [],
        start_date: null,
        end_date: null,
        is_active: true
    },
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      }
    }
  },
  mounted() {
    this.getAllPages();
    this.getAllScores();
    this.getCurrentUser();
  },
  computed: {
    selectAllPage () {
      return this.popup.applicable_pages.length === this.pages.length
    },
    selectedPage () {
      return this.popup.applicable_pages.length > 0 && !this.selectAllPage
    },
    iconPage () {
      if (this.selectAllPage) return 'mdi-close-box'
      if (this.selectedPage) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  methods:{
    closeDialog() {
      this.resetForm();
      this.dialog = false;
    },
    createPopup() {
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editPopup(popup) {
      this.resetForm();
      this.title = 'Edit';
      this.dialog = true;
      this.edit = true;
      this.popup = Object.assign({}, this.popup,  popup);
      if(popup.applicable_pages == null) {
        this.popup.applicable_pages = []
      }
      if(popup.applicable_scores == null) {
        this.popup.applicable_scores = []
        this.popup.score_for = true;
      } else {
        this.popup.score_for = false;
      }
    },
    changeScore() {
      if(this.popup.score_for) {
        this.popup.applicable_scores = []
      }
    },
    getAllPages() {
      popup.getPages().then(response => {
        this.pages = response.data.applicable_pages;
      })
    },
    getAllScores() {
      score.all().then(response => {
        this.scores = response.data.scores;
      })
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    selectAllScores () {
      this.$nextTick(() => {
        if (this.selectAllScore) {
          this.popup.applicable_scores = []
        } else {
          this.popup.applicable_scores = [];
          this.scores.forEach(score => {
            this.popup.applicable_scores.push(score.id);
          });
        }
      })
    },
    allSelectGrades() {
      this.$nextTick(() => {
        if (this.selectAllPage) {
          this.popup.applicable_pages = []
        } else {
          this.popup.applicable_pages = [];
          this.pages.forEach(page => {
            this.popup.applicable_pages.push(page.value);
          });
        }
      })
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.popup) {
        if (key === "uploadFile" && this.popup[key] != null && this.popup[key] != undefined) {
          formData.append('uploadFile', this.popup[key]);
        }else {
          if(this.popup[key]){
            formData.append(key,this.popup[key]);
          }
        }
      }
      return formData;
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if(this.popup.end_date < this.popup.start_date) {
          this.$snotify.error("View up to date must be greater than view from date");
          return;
        }
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      let form = this.convertToFormData();
      popup
          .create(form)
          .then(response => {
            this.$snotify.success("Popup added");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });

    },
    update: function () {
      let form = this.convertToFormData();
      popup
          .update(this.popup.id, form)
          .then((response) => {
            this.$snotify.success("Popup updated");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.popup = {
        name: '',
        description: '',
        display_title: true,
        score_for: true,
        type: 'text',
        uploadFile: null,
        link_url: '',
        video_link: '',
        applicable_pages: [],
        applicable_scores: [],
        start_date: null,
        end_date: null,
        is_active: true
      };
      this.errors = [];
      this.$v.$reset();
    },
  }
}
</script>

<style scoped>
  #logo_preview{
    height: 100px;
    width: 100px;
    object-fit: contain;
  }
</style>